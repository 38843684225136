import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Timeline.Item component is used to display items on a vertical timeline, connected by Timeline.Badge elements.`}</p>
    <h2>{`Example with in-line links`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Timeline>
  <Timeline.Item>
    <Timeline.Badge>
      <StyledOcticon icon={Flame} />
    </Timeline.Badge>
    <Timeline.Body>
      <Link href="#" fontWeight="bold" color="gray.8" mr={1} muted>
        Monalisa
      </Link>
      created one <Link href="#" fontWeight="bold" color="gray.8" mr={1} muted>
        hot potato
      </Link>
      <Link href="#" color="gray.7" muted>
        Just now
      </Link>
    </Timeline.Body>
  </Timeline.Item>
</Timeline>
`}</code></pre>
    <h2>{`Default Color example`}</h2>
    <p>{`The default Timeline.Badge color is dark text on a light grey background.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Timeline>
  <Timeline.Item>
    <Timeline.Badge>
      <StyledOcticon icon={Flame} />
    </Timeline.Badge>
    <Timeline.Body>Default badge color</Timeline.Body>
  </Timeline.Item>
</Timeline>
`}</code></pre>
    <h2>{`Adding color to a Badge`}</h2>
    <p>{`To have color variants, use the `}<inlineCode parentName="p">{`bg`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`Timeline.Badge`}</inlineCode>{`. If an icon is being used, set the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop
of the child `}<inlineCode parentName="p">{`StyledOcticon`}</inlineCode>{` if necessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Timeline>
  <Timeline.Item>
    <Timeline.Badge bg="red.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Red background used when closed events occur</Timeline.Body>
  </Timeline.Item>
  <Timeline.Item>
    <Timeline.Badge bg="green.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Green background when opened or passed events occur</Timeline.Body>
  </Timeline.Item>
  <Timeline.Item>
    <Timeline.Badge bg="turquoise.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Turquoise background used when pull requests are merged</Timeline.Body>
  </Timeline.Item>
  <Timeline.Item>
    <Timeline.Badge bg="blue.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Blue background to indicate new events below</Timeline.Body>
  </Timeline.Item>
</Timeline>
`}</code></pre>
    <h2>{`Condensed items`}</h2>
    <p>{`Timeline has a condensed prop that will reduce the vertical padding and remove the background from the badge item. These are most commonly used in commits. To condense a single item, remove the top or bottom padding with the `}<inlineCode parentName="p">{`pt={0}`}</inlineCode>{` or `}<inlineCode parentName="p">{`pb={0}`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Timeline>
  <Timeline.Item condensed>
    <Timeline.Badge>
      <StyledOcticon icon={GitCommit} />
    </Timeline.Badge>
    <Timeline.Body>This is the message of a condensed TimelineItem</Timeline.Body>
  </Timeline.Item>
  <Timeline.Item condensed>
    <Timeline.Badge>
      <StyledOcticon icon={GitCommit} />
    </Timeline.Badge>
    <Timeline.Body>This is the message of a condensed TimelineItem</Timeline.Body>
  </Timeline.Item>
</Timeline>
`}</code></pre>
    <h2>{`Timeline Break`}</h2>
    <p>{`To create a visual break in the timeline, use Timeline.Break. This adds a horizontal bar across the timeline to show that something has disrupted it. Usually this happens when a close or merged event occurs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Timeline>
  <Timeline.Item>
    <Timeline.Badge bg="red.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Red background used when closed events occur</Timeline.Body>
  </Timeline.Item>
  <Timeline.Break />
  <Timeline.Item>
    <Timeline.Badge bg="green.5">
      <StyledOcticon icon={Flame} color="white" />
    </Timeline.Badge>
    <Timeline.Body>Green background when opened or passed events occur</Timeline.Body>
  </Timeline.Item>
</Timeline>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Timeline and Timeline.Item components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h3>{`Timeline`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`clipSidebar`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hides the sidebar above the first Timeline.Item and below the last Timeline.Item.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Timeline.Item`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`condensed`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reduces vertical padding and removes background from an item's badge.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      